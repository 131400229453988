import { React, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';

export default function SearchBar() {
    const history = useNavigate();
    const location = useLocation();
    const [searchTerm, setSearchTerm] = useState(location.search.split("?")[1] || "");
    const [fileContent, setFileContent] = useState(location.state?.fileContent);
    const [fileName, setFileName] = useState(location.state?.fileName || "");
    const [fileError, setFileError] = useState(null);
    var disableButton = fileError != null || (!searchTerm && !fileContent);

    // Handle errors with uploaded file types
    const fileContentsToArray = (file) => {
        const reader = new FileReader();
        // get the text and set file contents
        reader.onload = async (e) => {
            const text = e.target.result;
            try {
                // parse content based on file extensions
                var formattedText = "";
                if (file.type === "application/json" || file.type === "text/javascript") {
                    formattedText = JSON.parse(text);
                } else if (file.type === "text/plain") {
                    // format the file content to array, ignoring valid comments
                    formattedText = text.split('\n').map((entry) => {
                        return entry.trim();
                    }).filter(entry => entry !== '' && entry[0] !== '#');
                } else { // invalid file type
                    window.alert("Please input a valid file type: .txt, .js, or .json!");
                    return;
                }
                setFileContent(formattedText);
                setFileError(null);
            } catch (e){
                // Update file content in order to display API error response
                setFileError(e.message);
                console.error(e);
            }
        }
        setFileName(file.name);
        // call the reader 
        reader.readAsText(file);
    }

    // update search term from input
    const handleChange = (e) => {
        // separate search term(s) by commas and/or white spaces
        const input = e.target.value.split(/[\s,]+/);
        // check for multiple hostnames (POST request) 
        if (input.length > 1) {
            setFileContent(input);
        } else {
            setFileContent(null);
        }
        setSearchTerm(e.target.value);
        setFileName("");
        // clear prior file error
        setFileError(null);
    }

    // handle uploaded file
    const handleNewFile = (e) => {
        const file = e.target.files[0];
        // prevent null file processing 
        if (!file) return;
        // format and set file contents 
        fileContentsToArray(file);
        // clear search term
        setSearchTerm("");
    }

    // Update URL to lookup results, clearing previous file/text input
    const handleClickSearch = () => {
        // check for multiple hostnames 
        if (searchTerm !== "" && !fileContent) {
            // redirect to new page
            history(`/lookup?${searchTerm}`)
        } else if (fileContent) {
            // send to lookup component, and set file as fileContents
            history('/lookup', { state: { 'fileContent': fileContent, 'fileName': fileName } })
        }
    }

    const handleDrop = (event) => {
        // prevent default behavior from opening file
        event.preventDefault();
        const data = event.dataTransfer.files[0];
        fileContentsToArray(data);
        setSearchTerm("");
        // remove drag styling effects
        handleDragLeave();
        document.querySelector(".file-upload").children[0].style.pointerEvents = "auto";
    }

    const handleDragOver = (event) => {
        // prevent default behavior from opening file
        event.preventDefault();
    }

    const handleDragEnter = () => {
        document.querySelector(".file-upload").classList.add("drag");
        // prevent drag event from leaving upon entering child elements 
        document.querySelector(".file-upload").children[0].style.pointerEvents = "none";
    }

    const handleDragLeave = () => {
        document.querySelector(".file-upload").classList.remove("drag");
    }

    const handleRemoveFile = (event) => {
        setFileContent(null);
        setFileName("");
        setFileError(false);
        event.preventDefault();
    }
    
    const handleSubmit = (event) => {
        event.preventDefault();
    }

    return <form style={{ margin: '1rem 0' }} onSubmit={handleSubmit}>
        <div style={{ display: 'flex', flexDirection: 'row' }}>
            <input
                type="search"
                placeholder='Router hostnames to geolocate (e.g. ae6.mpr1.tor3.ca.zip.zayo.com)'
                onChange={handleChange}
                value={searchTerm}
                style={{ width: '100%', fontSize: '1.2rem', marginRight: '2px' }}
            />
            <div className="tooltip-area">
                <button onClick={handleClickSearch} aria-disabled={disableButton} aria-describedby="lookup" type="submit">
                    Lookup
                </button>
                {disableButton && <div role="tooltip" id="lookup"><span>Input hostname or <br/> upload a file</span></div>}
            </div>
        </div>
        <div className="file-upload" onDrop={handleDrop} onDragOver={handleDragOver} onDragEnter={handleDragEnter} onDragLeave={handleDragLeave}> 
            <label htmlFor="upload">
                { fileName === "" 
                    ? <p>Drag and drop or <span>upload a file</span></p>
                    : <p style={{display: "flex"}}>{fileName} <HighlightOffIcon sx={{ ml: ".5rem", "&:hover": {color: "red"}}} onClick={handleRemoveFile}/></p>
                } 
                {/* set empty file value to allow the same file to be selected again */}
                <input type="file" id="upload" onChange={handleNewFile} value="" accept=".txt, .js, .json"/>
            </label>
        </div>
        {fileError && <p className="file-error">Error with file format: <strong>{fileError.toLowerCase()}</strong></p>}
    </form>
}