export const fetchData = (searchTerm, type) => {
    let url = 'https://api.hoiho.caida.org/lookups';
    let requestOptions = {} 

    if (type === 'GET') {
        url += `/${searchTerm}`;
        requestOptions = {
            method: 'GET',
            headers: { 'Content-Type': 'application/json' }
        };
    } else if (type === 'POST') {
        // add in body of json data from file upload
        requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(searchTerm)
        };
    }

    return fetch(url, requestOptions)
        .then(res => res.json())
        .then((data) => ['valid', data])
        .catch((error) => {
            return ['error', error];
        })
}