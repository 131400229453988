import { React } from 'react';
import { Container } from '@mui/system';
import ResponseFields from './components/ResponseFields';
import { LookupSyntax } from './Home';

export default function Help() {
    return <Container>
        <div>
            <h1>Need help?</h1>
            {/* <p>Hoiho &#40;Holistic Orthography of Internet Hostname Observations&#41; uses hostnames to infer ASNs and geographic locations with the latest <a href="https://catalog.caida.org/dataset/ark_itdk">ITDK</a>.</p> */}
            <p>This is an interface to the Hoiho &#40;Holistic Orthography of Internet Hostname Observations&#41; inferences
                included in the latest <a href="https://catalog.caida.org/dataset/ark_itdk">Internet Topology Data Kit (ITDK<sup>1</sup>)</a>.
            </p>
            <p><strong>Hoiho API Documentation</strong> available at <a href='https://api.hoiho.caida.org/'>https://api.hoiho.caida.org/</a>.</p>
            <h2>Background</h2>
            <p>Researchers and network operators have used information encoded in the Domain Name System (DNS) to understand properties
                of the network for at least two decades. To aid network management, operators often use DNS hostname strings to encode
                information about the name of the router, location, role, or interconnection properties of router interfaces – the
                hardware components that connect to other routers.<sup>2</sup>
            </p>
            <p>
                Similarly, operators independently decide where in the hostname they encode the geographic hints (geohints).
                Individual conventions range from embedding International Air Transport Association (IATA) airport codes to other information
                about the geolocation, the physical location of an Internet Protocol (IP) address, including:
                Common Language Location Identifier (CLLI) prefixes, United Nations Code for Trade and Transport Locations (UN/LOCODEs),
                city names, country codes, or state codes.<sup>3</sup>
            </p>
            <p>
                However, operators have never developed consensus on a universal naming convention – each networked organization
                independently selects such conventions for their own suffix (e.g., savvis.net).
                As a result, the diversity in conventions makes it difficult for researchers and network operators to
                systematically use information encoded in hostnames and reason about the physical location of Internet routers.
            </p>
            <p>Using CAIDA's latest ITDK as training data to learn router naming conventions,
                our tool Hoiho, named after a flightless native New Zealand bird, evaluates automatically generated candidate
                regular expressions (regexes) against sets of router hostnames with the same suffix.<sup>2</sup> Hoiho has been able to
                extend from extracting router names to autonomous system numbers (ASNs) that operate routers, even when operators use
                unconventional naming or customized geohints.<sup>3</sup>
            </p>

            <h2>Lookup Syntax</h2>
            <LookupSyntax />
            {/* <p>Text input: whitespace delimited hostnames</p>
            <p>File upload: one hostname per line in file</p> */}
            
            <ResponseFields id="response-fields"/>
            <hr />
            
            <h2>References</h2>
            <p>
                <sup>1</sup>
                    CAIDA. 2019. Macroscopic Internet Topology Data Kit (ITDK). <a 
                    href="https://www.caida.org/data/internet-topology-data-kit/">https://www.caida.org/data/internet-topology-data-kit/</a>.
            </p>
            <p>
                <sup>2</sup>
                    Luckie, M., Huffaker, B. & claffy, k.  (2019). Learning Regexes to Extract Router Names from Hostnames. ACM 
                    Internet Measurement Conference (IMC), <a href="https://catalog.caida.org/paper/2019_learning_regexes_extract_router">
                    https://catalog.caida.org/paper/2019_learning_regexes_extract_router</a>.
            </p>
            <p>
                <sup>3</sup>
                    Luckie, M., Huffaker, B., Marder, A., Bischof, Z., Fletcher, M. & claffy, k. (2021). 
                    Learning to Extract Geographic Information from Internet Router Hostnames. ACM SIGCOMM Conference on emerging Networking
                    EXperiments and Technologies (CoNEXT), <a href="https://catalog.caida.org/paper/2021_learning_extract_geographic_information">
                    https://catalog.caida.org/paper/2021_learning_extract_geographic_information</a>.
            </p>
        </div>
    </Container>
}
