import { React, useState, useEffect } from 'react';
import { useLocation } from "react-router-dom";
import { HashLink as Link } from 'react-router-hash-link';
import { Container } from '@mui/system';
// Components
import SearchBar from './components/SearchBar';
import { fetchData } from './components/FetchData';
import ResponseFields from './components/ResponseFields';
// Icons
import copyToClipboard from '../images/icon-copyClipboard.png';
import checkMark from '../images/icon-checkmark.gif';
import { ExampleTextFile, LookupSyntax } from './Home';

function DownloadResultButton(props) {
    const { results } = props
    /**
     * Function that downloads the results as JSON
     */
    function handleDownload() {
        const file = new Blob([JSON.stringify(results)], { type: 'text/plain' })
        const element = document.createElement("a")
        element.href = URL.createObjectURL(file)
        element.download = "hoiho-results" + Date.now() + ".json"
        document.body.appendChild(element)
        element.click()
    }

    return <button title='download results as json' onClick={handleDownload} value='download'>
            Download
        </button>
}

export default function Lookup() {
    // get path details and provided search term or file 
    const urlParams = useLocation();
    const searchTerm = urlParams.search.split('?')[1] || "";
    const [api, setAPI] = useState("");
    const [results, setResults] = useState(null);
    const file = urlParams.state?.fileContent;
    const [copied, setCopied] = useState(false);
    // define response fields (not every hostname matched will have values to every field)
    const cols = ["hostname", "match_strs", "match_meanings", "locode", "clli", "iata", "place", "st", "cc", "lat", "lng"];

    // threshold of results to show
    const threshold = 50;

    // get new results with new searchterm
    useEffect(() => {
        if (searchTerm) {
            fetchData(searchTerm, 'GET')
                .then((data) => {
                    setResults(data[1]);
                })
            // update api link with the search term
            setAPI(`https://api.hoiho.caida.org/lookups/${searchTerm}`);
        }
    }, [searchTerm])

    // get new results with uploaded file
    useEffect(() => {
        if (file) {
            fetchData(file, 'POST')
                .then((data) => {
                    setResults(data[1]);
                })
            // set api link back to its initial state
            setAPI("");
        }
    }, [file])

    const copy = () => {
        navigator.clipboard.writeText(api);
        setCopied(true);
        // wait for a few seconds before unsetting copied state
        setTimeout(() => setCopied(false), 2000);
    }

    return <Container>
        <SearchBar />

        {/* Display lookup instructions if no query in url */}
        {
            !results && <LookupSyntax />
        }

        <h1>Hoiho API</h1>
        <div>
            API Documentation available at <a href="https://api.hoiho.caida.org/">https://api.hoiho.caida.org/</a>.
        </div>

        {/* <div>{searchTerm}</div> */}

        {
            api !== "" && <fieldset className="lookup__api">
                <legend style={{ marginLeft: "0.5rem" }}>API</legend>
                <a href={api} className="lookup__apiLink">{api}</a>
                <button title="Copy to clipboard"
                    className="lookup__copy"
                    disabled={copied}
                    onClick={copy}>
                    <img src={copied ? checkMark : copyToClipboard}
                        alt="Copy to clipboard icon"
                        style={{ display: "block" }} />
                </button>
            </fieldset>
        }

        <div style={{ marginTop: '2rem'}}>
        {results &&
            <h2>Hoiho Results &nbsp;
                <DownloadResultButton results={results} />
            </h2>
        }
        {/* Always display result summary on valid inputs */}
        {results && results.summary &&
            <div>
                <p><b>Summary</b></p>
                <div style={{ marginLeft: '1rem' }}>
                    <table>
                        <tbody>
                            {
                                results && results.summary && Object.entries(results.summary).map(([key, value]) => {
                                    return <tr key={key}>
                                        <th>{key}</th>
                                        <td>{value}</td>
                                    </tr>
                                })
                            }
                        </tbody>
                    </table>
                </div>

                {!results.matches 
                    ? <div>                
                        <p>0 hostnames matched.</p>
                        { file && 
                            <p>Please check your file for the correct format indicated on the <Link to="/">home page</Link> or the example files below:</p>
                            && <ExampleTextFile />
                        }
                    </div>
                    : <div>
                        <div>
                            <p><b>Records</b></p>
                            {/* TODO: Allow extra columns from data */}
                            <div style={{ marginLeft: '1rem' }}>
                                <table>
                                    <thead>
                                        <tr>
                                            {
                                                cols.map((colHeader) => {
                                                    return <th key={colHeader}>{colHeader}</th>
                                                })
                                            }
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            results && results.matches?.slice(0, threshold).map((row, index) => {
                                                return <tr key={index}>
                                                    {
                                                        cols.map((colKey) => {
                                                            if (colKey === 'match_strs' || colKey === 'match_meanings') {
                                                                return <td key={colKey}>{row[colKey].toString()}</td>
                                                            } else {
                                                                return <td key={colKey}>{row[colKey]}</td>
                                                            }
                                                        })
                                                    }
                                                </tr>
                                            })
                                        }
                                    </tbody>
                                </table>
                                {
                                    results && results.matches?.length > threshold && <div style={{ marginTop: '1rem' }}>
                                        Showing {threshold} of {results.matches?.length} results, to see all results <DownloadResultButton results={results} /> as json
                                    </div>
                                }
                            </div>
                        </div>
                        <ResponseFields />
                    </div>
                }
            </div>
        }
        </div>
    </Container>
}