export default function Footer() {
    const year = new Date().getFullYear();
    return <div className="footer">
        <div className="footer__content">
            <div className="footer__copyright">
                <p>Ⓒ Copyright {year} &#8212; Regents of the University of California<br/>All Rights Reserved.</p>
            </div>

            <div className="footer__support">
                <p>Support for this work comes from the NSF Award OAC-2131987.</p>
            </div>
        </div>
        <div className="footer__sub">
            <div className="footer__links">
                <a href="https://www.caida.org/home/legal/privacy/" className="footer__links-item">
                    Privacy Policy
                </a>
            </div>
        </div>
    </div>
}