import React from 'react';
import {
  Route,
  Routes,
  BrowserRouter as Router,
} from "react-router-dom";
import Lookup from './js/Lookup'
import Home from './js/Home'
import Help from './js/Help'
import Footer from './js/components/Footer'
import Nav from './js/components/Nav'

function App() {
  return (
    <Router>
      <div id ='page=container'>
        <Nav />
        <div className="content">
            <Routes>
              <Route path="/" element={<Home />} />
              <Route path="/help" element={<Help />} />
              <Route path="/:route" element={<Lookup />} />
            </Routes>
        </div>
        <Footer />
      </div>
    </Router>
  );
}

export default App;
