import { Container } from '@mui/system';
import { Link } from 'react-router-dom';
import caidaLogo from '../../images/CaidaLogoFaded.svg';

export default function Nav() {
    return <div className="nav__bg">
        <Container sx={{ marginBottom: '1rem', display: 'flex', alignItems: 'center', height: '5rem'}}>
            <div className="nav__logo">
                <a href="https://www.caida.org/">
                    <img src={caidaLogo} alt="Caida Logo" width="125" />
                </a>
                <Link className='home__link' to="/">Hoiho - Holistic Orthography of Internet Hostname Observations</Link>
            </div>
            <nav>
                <Link to="/help">Help</Link>
            </nav>
        </Container>
    </div>
}