import React from 'react';
import { Container } from '@mui/system';
import SearchBar from './components/SearchBar';

export function LookupSyntax() {
    return <div style={{ marginBottom: '2rem' }}>
        To input more than one router hostname in the text field, use a <strong>comma</strong> or <strong>whitespace</strong> as a delimiter between each hostname.
        <br/>
        The POST interface works best with files containing 10K hostnames:
        these take about 30 seconds to complete.
        <br/>
        To upload a file, provide one of the following types:
        <ul>
            <li><strong>.txt</strong>: router hostnames separated by each line, comments starting with # are valid</li>
            <li><strong>.json</strong> or <strong>.js</strong>: JSON array of router hostnames (string values)</li>
        </ul> 
        <span>

        Each router hostname should contain only characters that are valid in the DNS -- a-z, A-Z, 0-9, '-', and '.'.  However, the API will accept any sequence of characters in a hostname, as operators sometimes use invalid characters.  If you upload non-standard characters in JSON, please ensure that you correctly escape them so that the JSON is still valid.
	<br/>
        For example, these are valid files:
        </span>
        <ExampleTextFile />
    </div>
}

export function ExampleTextFile() {
    return <div className='example-file'>
        <span>example.txt</span>
        <pre> 
            # this is a comment line <br />
            ae6.mpr1.tor3.ca.zip.zayo.com<br />
            100.ae5.mpr2.pdx1.us.zip.zayo.com
        </pre>
        <span>example.json</span> or <span>example.js</span>
        <pre>
            [
                "ae6.mpr1.tor3.ca.zip.zayo.com",
                "100.ae5.mpr2.pdx1.us.zip.zayo.com"
            ]
        </pre>
    </div>
}

export default function Home() {
    return <Container>
        <div style={{ marginBottom: '1rem', marginTop: '2rem' }}>
            This website uses Hoiho to infer geographic locations from router DNS hostnames with known geolocation inferences.
            Please either input one or more hostnames below or upload a file of hostname(s). 
        </div>
        <SearchBar />
        <LookupSyntax />
        <div>
            <p><strong>Hoiho API Documentation</strong> available at <a href='https://api.hoiho.caida.org/'>https://api.hoiho.caida.org/</a>.</p>
            For more information about how Hoiho works, please read:
            <ul>
                <li>
                    <a href="https://catalog.caida.org/paper/2021_learning_extract_geographic_information">
                        Learning to Extract Geographic Information from Internet Router Hostnames
                    </a>
                </li>
            </ul>
            <br/>
            <p>When referencing this data (as required by the AUA), please use:</p>
            <blockquote>
                CAIDA Hoiho &#40;Holistic Orthography of Internet Hostname Observations&#41; API - &lt;date&gt;
                <br/>
                https://catalog.caida.org/dataset/hoiho
            </blockquote>
            <blockquote>
            Luckie, M., Huffaker, B., Marder, A., Bischof, Z., Fletcher, M., and claffy, k., "Learning to Extract Geographic Information from Internet Router Hostnames." ACM SIGCOMM Conference on emerging Networking EXperiments and Technologies (CoNEXT), December 2021, 
            <br />
            https://catalog.caida.org/paper/2021_learning_extract_geographic_information
            </blockquote>
        </div>
    </Container>
}
