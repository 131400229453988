export default function ResponseFields() {
    const fields = {
        "summary": {
            "ruleset_date": "date of ITDK used to infer rules",
            "hostnames_requested": "number of router hostnames looked up from the searchbar or file",
            "hostnames_matched": "number of matches to the router hostname(s) provided"
        },
        "matches": {
            "hostname": "router hostname that matched",
            "match_strs": "matched string(s) in the router hostname",
            "match_meanings": "matched response field(s) to the string(s) in the router hostname",
            "locode": "UN/LOCODE (United Nations Code for Trade and Transport Locations), a geographic coding scheme maintained by UNECE",
            "clli": "Common Language Information Services identifier, a geographic coding scheme maintained by iconectiv",
            "iata": "IATA (International Air Transport Association) airport code; note, some operators create their own 3-letter codes for some locations",
            "place": "city",
            "st": "state",
            "cc": "country",
            "lat": "latitude",
            "lng": "longitude"
        }
    }

    return <div id="response-fields">
        <h2>Response Fields</h2>
        {
            Object.keys(fields).map((field, key) => {
                return <div key={key}>
                    <p className="help__response-fields"><strong>{field}</strong></p>
                    <ul>{
                        Object.entries(fields[field]).map((entry, key) => {
                            return <li key={key}><strong>{entry[0]}</strong>: {entry[1]}</li>
                        })
                    }</ul>
                </div>
            })
        }
    </div>
}
